<template>
  <div>
    <template v-if="existsComponent">
      <component :is="component"></component>
    </template>
    <template v-else>
      <default-info></default-info>
    </template>
  </div>
</template>

<script>
    import {mapState} from 'vuex';
    import CustomerInfo from "./CustomerInfo";
    import AuthorInfo from "./AuthorInfo";
    import DefaultInfo from "./DefaultInfo";

    export default {
        name: "MyAccount",
        data() {
            return {
                component: '',
            }
        },
        computed: {
            ...mapState({
                'user': state => state.auth.user,
            })
        },

        mounted() {
            this.component = this.defineComponent()
        },

        methods: {
            defineComponent() {
                let component = this.capitalize(`${this.user.role}Info`);
                if (this.existsComponent(component)) {
                    return component;
                }
                return 'DefaultInfo';
            },

            existsComponent(component) {
                return this.$options.components[component] !== undefined;
            },

            capitalize(word) {
                return word.charAt(0).toUpperCase() + word.slice(1);
            }
        },

        components: {
            CustomerInfo,
            AuthorInfo,
            DefaultInfo
        }
    }
</script>

<style scoped>

</style>
